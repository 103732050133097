.map-page {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.back-button {
  position: absolute;
  left: calc(5% - 20px);
}

.map-title-icon {
  margin-right: 20px;
  max-height: 60px;
  max-width: 60px;
  height: auto;
  width: auto;
}

.top-container {
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  height: 125px;
  width: 100%;
  z-index: 999;
  background: var(--background);
}

.top-container-blur {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: -6px;
  filter: blur(3px);
}

.top-container-shadow {
  height: 15px;
  width: 100%;
  position: absolute;
  background: var(--darker-shadow);
  filter: blur(1px);
}

.top-container-ambient {
  height: 50%;
  width: 100%;
  top: 0;
  position: absolute;
  background: var(--background);
}

.item-map-container {
  position: absolute;
  width: 100%;
  top: 125px;
  height: calc(100vh - 125px);
  overflow: hidden;
}
