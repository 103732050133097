.round-input {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 75px;
  width: 75px;
  margin-bottom: 25px;
  margin-top: -5px;
}

.round-input-img {
  height: 90px;
  width: 90px;
  position: absolute;
}

.round-input-text {
  position: relative;
  background: transparent;
  border: none;
  width: 100%;
  outline-color: transparent;
  outline-style: none;
  text-align: center;
  margin-top: 3px;
}
