@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');

@font-face {
  font-family: 'Axiforma-Light';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Light.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Axiforma-Book';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/5c59b0a881353e000dcaa8e8/Kastelov - Axiforma Book.otf')
    format('opentype');
}

@font-face {
  font-family: 'Axiforma-Medium';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Axiforma-SemiBold';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/5c59b0a881353e000dcaa8e8/Kastelov - Axiforma SemiBold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Axiforma-Bold';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Bold.ttf')
    format('truetype');
}

* {
  box-sizing: border-box;
}

.font-axiforma-light {
  font-family: 'Axiforma-Light', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 300;
}

.font-axiforma-book {
  font-family: 'Axiforma-Book', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 400;
}

.font-axiforma-medium {
  font-family: 'Axiforma-Medium', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 500;
}

.font-axiforma-semi-bold {
  font-family: 'Axiforma-SemiBold', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 600;
}

.font-axiforma-bold {
  font-family: 'Axiforma-Bold', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 700;
}

.text-blue {
  color: var(--blue);
}

.text-title {
  font-size: 40px;
}

.text-medium {
  font-size: 20px;
}

.text-small {
  font-size: 16px;
}

.text-error {
  color: var(--error-red);
  font-family: 'Axiforma-Light', Helvetica, Arial, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.hidden {
  display: none;
  visibility: hidden;
}

.clear {
  pointer-events: none;
}

:root {
  --blue: rgb(38, 115, 232);
  --half-blue: rgb(143, 168, 207);
  --background: rgb(238, 242, 251);
  --semi-background: rgba(238, 242, 251, 0.9);
  --error-red: rgb(255, 75, 76);

  --light: rgb(249, 251, 255);
  --lighter-light: rgb(255, 255, 255);
  --shadow: rgb(227, 233, 245);
  --darker-shadow: rgb(210, 220, 241);
  --darkest-shadow: rgb(185, 200, 231);
}
