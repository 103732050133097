.center-login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.login-logo {
  width: 100px;
  margin-bottom: 25px;
}

.login-error {
  margin-top: 25px;
}
