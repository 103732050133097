@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@font-face {
  font-family: 'Axiforma-Light';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Light.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Axiforma-Book';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/5c59b0a881353e000dcaa8e8/Kastelov - Axiforma Book.otf')
    format('opentype');
}

@font-face {
  font-family: 'Axiforma-Medium';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Axiforma-SemiBold';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/5c59b0a881353e000dcaa8e8/Kastelov - Axiforma SemiBold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Axiforma-Bold';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Bold.ttf')
    format('truetype');
}

* {
  box-sizing: border-box;
}

.font-axiforma-light {
  font-family: 'Axiforma-Light', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 300;
}

.font-axiforma-book {
  font-family: 'Axiforma-Book', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 400;
}

.font-axiforma-medium {
  font-family: 'Axiforma-Medium', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 500;
}

.font-axiforma-semi-bold {
  font-family: 'Axiforma-SemiBold', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 600;
}

.font-axiforma-bold {
  font-family: 'Axiforma-Bold', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 700;
}

.text-blue {
  color: rgb(38, 115, 232);
  color: var(--blue);
}

.text-title {
  font-size: 40px;
}

.text-medium {
  font-size: 20px;
}

.text-small {
  font-size: 16px;
}

.text-error {
  color: rgb(255, 75, 76);
  color: var(--error-red);
  font-family: 'Axiforma-Light', Helvetica, Arial, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.hidden {
  display: none;
  visibility: hidden;
}

.clear {
  pointer-events: none;
}

:root {
  --blue: rgb(38, 115, 232);
  --half-blue: rgb(143, 168, 207);
  --background: rgb(238, 242, 251);
  --semi-background: rgba(238, 242, 251, 0.9);
  --error-red: rgb(255, 75, 76);

  --light: rgb(249, 251, 255);
  --lighter-light: rgb(255, 255, 255);
  --shadow: rgb(227, 233, 245);
  --darker-shadow: rgb(210, 220, 241);
  --darkest-shadow: rgb(185, 200, 231);
}

.search-bar {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 65px;
}

.search-mask {
  border-radius: 25px;
  height: 55px;
  overflow: hidden;
  position: absolute;
}

.search-shadow {
  border-radius: 25px;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  border: 12px solid var(--darker-shadow);
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.search-light {
  border-radius: 25px;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  border: 12px solid var(--lighter-light);
  height: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.search-blur {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-filter: blur(4px);
          filter: blur(4px);
  border-radius: 25px;
  height: 75px;
  position: absolute;
}

.search-text {
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background: transparent;
  border: none;
  outline-color: transparent;
  outline-style: none;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--half-blue);
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--half-blue);
}

::placeholder {
  /* Most modern browsers support this now. */
  color: var(--half-blue);
}

.search-page {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}

.search-title {
  text-align: center;
}

.result-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin-top: 50px;
}

.item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 30px;
  height: 160px;
  width: 160px;
  margin: 20px;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transform-origin: center;
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  will-change: transform;
}

.item-icon {
  position: relative;
  max-height: 60px;
  max-width: 60px;
  height: auto;
  width: auto;
}

.item-text {
  position: relative;
  text-align: center;
  margin-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.log-out {
  position: absolute;
  margin-top: 50px;
  margin-right: 50px;
  top: 0;
  right: 0;
}

.icon-container {
  display: flex;
  position: relative;
  flex-direction: column;
  will-change: transform;
}

.round-icon {
  position: relative;
  height: 30px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-pressed {
  background: linear-gradient(
    157.37deg,
    var(--darker-shadow) 13.29%,
    var(--lighter-light) 84.76%
  );
  -webkit-filter: blur(6px);
          filter: blur(6px);
  position: absolute;
  will-change: opacity;
}

.button-unpressed {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  will-change: opacity;
}

.button-text {
  position: absolute;
  will-change: transform;
  text-align: center;
}

.password {
  -webkit-text-security: disc;
  text-security: disc;
}

.neumorphic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: visible;
}

.map-page {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.back-button {
  position: absolute;
  left: calc(5% - 20px);
}

.map-title-icon {
  margin-right: 20px;
  max-height: 60px;
  max-width: 60px;
  height: auto;
  width: auto;
}

.top-container {
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  height: 125px;
  width: 100%;
  z-index: 999;
  background: var(--background);
}

.top-container-blur {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: -6px;
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.top-container-shadow {
  height: 15px;
  width: 100%;
  position: absolute;
  background: var(--darker-shadow);
  -webkit-filter: blur(1px);
          filter: blur(1px);
}

.top-container-ambient {
  height: 50%;
  width: 100%;
  top: 0;
  position: absolute;
  background: var(--background);
}

.item-map-container {
  position: absolute;
  width: 100%;
  top: 125px;
  height: calc(100vh - 125px);
  overflow: hidden;
}

@-webkit-keyframes bounce {
  0%,
  40%,
  70%,
  80%,
  100% {
    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
  20% {
    -webkit-transform: translateY(-12px) scale(1.2);
    transform: translateY(-12px) scale(1.2);
  }
  60% {
    -webkit-transform: translateY(-3px) scale(1);
    transform: translateY(-3px) scale(1);
  }
}

@keyframes bounce {
  0%,
  40%,
  70%,
  80%,
  100% {
    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
  20% {
    -webkit-transform: translateY(-12px) scale(1.2);
    transform: translateY(-12px) scale(1.2);
  }
  60% {
    -webkit-transform: translateY(-3px) scale(1);
    transform: translateY(-3px) scale(1);
  }
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: translateY(-300px) scale(0);
            transform: translateY(-300px) scale(0);
  }
  40% {
    -webkit-transform: translateY(15px) scale(1.1) rotate(-1.5deg);
            transform: translateY(15px) scale(1.1) rotate(-1.5deg);
  }
  70% {
    -webkit-transform: scale(1) rotate(0.75deg);
            transform: scale(1) rotate(0.75deg);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale {
  0% {
    -webkit-transform: translateY(-300px) scale(0);
            transform: translateY(-300px) scale(0);
  }
  40% {
    -webkit-transform: translateY(15px) scale(1.1) rotate(-1.5deg);
            transform: translateY(15px) scale(1.1) rotate(-1.5deg);
  }
  70% {
    -webkit-transform: scale(1) rotate(0.75deg);
            transform: scale(1) rotate(0.75deg);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.tracker {
  border: none;
  background: none;
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.marker-info {
  margin-top: 2px;
}

.tracker-animation {
  cursor: pointer;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -moz-animation-name: bounce;

  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.popup {
  z-index: 9;
}

.popup-animation {
  border-radius: 15px;
  padding: 20px 20px 15px;
  background: linear-gradient(
    157.37deg,
    var(--light) 13.29%,
    var(--background) 84.76%
  );
  box-shadow: 8px 8px 8px var(--darkest-shadow);

  cursor: pointer;
  -webkit-animation-name: scale;
          animation-name: scale;
  -moz-animation-name: scale;

  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.mapboxgl-popup-content {
  box-shadow: none;
  background-color: transparent;
  margin-top: 10px;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: var(--light);
  display: none;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: var(--light);
  display: none;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--light);
  display: none;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--light);
  display: none;
}

.round-input {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 75px;
  width: 75px;
  margin-bottom: 25px;
  margin-top: -5px;
}

.round-input-img {
  height: 90px;
  width: 90px;
  position: absolute;
}

.round-input-text {
  position: relative;
  background: transparent;
  border: none;
  width: 100%;
  outline-color: transparent;
  outline-style: none;
  text-align: center;
  margin-top: 3px;
}

@-webkit-keyframes expand-and-fade {
  0% {
    -webkit-transform: scale(0.3333);
            transform: scale(0.3333);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

@keyframes expand-and-fade {
  0% {
    -webkit-transform: scale(0.3333);
            transform: scale(0.3333);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

.user-container {
  align-items: center;
  justify-content: center;
  display: flex;
}

.user-animation {
  background-color: var(--blue);
  border-radius: 30px;
  height: 60px;
  width: 60px;
  left: -30px;
  top: -30px;
  position: absolute;

  -webkit-animation-name: expand-and-fade;

          animation-name: expand-and-fade;
  -moz-animation-name: expand-and-fade;

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.user-marker {
  background-color: var(--blue);
  border-radius: 15px;
  box-shadow: 0 0 30px var(--darkest-shadow);
  height: 20px;
  width: 20px;
  left: -10px;
  top: -10px;
  position: absolute;
}

.map-total-container {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 90vw;
  top: 175px;
  bottom: 4.5vw;
  position: fixed;
}

.map-container {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.map {
  top: 0;
  position: absolute;
  z-index: 0;
}

.map-control-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
}

.map-control-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
}

.separator {
  height: 50px;
}

.center-login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.login-logo {
  width: 100px;
  margin-bottom: 25px;
}

.login-error {
  margin-top: 25px;
}

