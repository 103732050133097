.icon-container {
  display: flex;
  position: relative;
  flex-direction: column;
  will-change: transform;
}

.round-icon {
  position: relative;
  height: 30px;
}
