.search-bar {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 65px;
}

.search-mask {
  border-radius: 25px;
  height: 55px;
  overflow: hidden;
  position: absolute;
}

.search-shadow {
  border-radius: 25px;
  filter: blur(2px);
  border: 12px solid var(--darker-shadow);
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.search-light {
  border-radius: 25px;
  filter: blur(2px);
  border: 12px solid var(--lighter-light);
  height: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.search-blur {
  display: flex;
  align-items: center;
  justify-content: center;
  filter: blur(4px);
  border-radius: 25px;
  height: 75px;
  position: absolute;
}

.search-text {
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background: transparent;
  border: none;
  outline-color: transparent;
  outline-style: none;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--half-blue);
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--half-blue);
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--half-blue);
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--half-blue);
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--half-blue);
}

::placeholder {
  /* Most modern browsers support this now. */
  color: var(--half-blue);
}
