.map-total-container {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 90vw;
  top: 175px;
  bottom: 4.5vw;
  position: fixed;
}

.map-container {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.map {
  top: 0;
  position: absolute;
  z-index: 0;
}

.map-control-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
}

.map-control-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
}

.separator {
  height: 50px;
}
