.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-pressed {
  background: linear-gradient(
    157.37deg,
    var(--darker-shadow) 13.29%,
    var(--lighter-light) 84.76%
  );
  filter: blur(6px);
  position: absolute;
  will-change: opacity;
}

.button-unpressed {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  will-change: opacity;
}

.button-text {
  position: absolute;
  will-change: transform;
  text-align: center;
}

.password {
  -webkit-text-security: disc;
  text-security: disc;
}
