.search-page {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}

.search-title {
  text-align: center;
}

.result-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin-top: 50px;
}

.item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 30px;
  height: 160px;
  width: 160px;
  margin: 20px;
  transform: scale(0.9);
  -webkit-transform-origin: center;
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  will-change: transform;
}

.item-icon {
  position: relative;
  max-height: 60px;
  max-width: 60px;
  height: auto;
  width: auto;
}

.item-text {
  position: relative;
  text-align: center;
  margin-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
}
