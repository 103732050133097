@keyframes bounce {
  0%,
  40%,
  70%,
  80%,
  100% {
    -webkit-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
  20% {
    -webkit-transform: translateY(-12px) scale(1.2);
    -ms-transform: translateY(-12px) scale(1.2);
    transform: translateY(-12px) scale(1.2);
  }
  60% {
    -webkit-transform: translateY(-3px) scale(1);
    -ms-transform: translateY(-3px) scale(1);
    transform: translateY(-3px) scale(1);
  }
}

@keyframes scale {
  0% {
    transform: translateY(-300px) scale(0);
  }
  40% {
    transform: translateY(15px) scale(1.1) rotate(-1.5deg);
  }
  70% {
    transform: scale(1) rotate(0.75deg);
  }
  100% {
    transform: scale(1);
  }
}

.tracker {
  border: none;
  background: none;
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.marker-info {
  margin-top: 2px;
}

.tracker-animation {
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;

  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.popup {
  z-index: 9;
}

.popup-animation {
  border-radius: 15px;
  padding: 20px 20px 15px;
  background: linear-gradient(
    157.37deg,
    var(--light) 13.29%,
    var(--background) 84.76%
  );
  box-shadow: 8px 8px 8px var(--darkest-shadow);

  cursor: pointer;
  animation-name: scale;
  -moz-animation-name: scale;

  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.mapboxgl-popup-content {
  box-shadow: none;
  background-color: transparent;
  margin-top: 10px;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: var(--light);
  display: none;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: var(--light);
  display: none;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--light);
  display: none;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--light);
  display: none;
}
